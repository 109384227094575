import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Typography from "../../Typography"
import bgImg from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-numbers.png"

interface BoxProps {
    number: string
    text: string
}
const Box: React.FC<BoxProps> = ({ number, text }) => {
    return (
        <div className="relative bg-horizen-bg border-2 border-horizen-purple-2 rounded-[10px] h-[220px] lg:h-[265px] w-full flex flex-col justify-center items-center gap-1">
            <span className="absolute top-0 left-1/2 -translate-x-1/2 border-solid border-t-horizen-purple-2 border-t-[24px] border-x-transparent border-x-[24px] border-b-0" />
            <span className="font-roboto text-horizen-white-1 font-bold text-5xl lg:text-7xl">
                {number}
            </span>
            <span className="font-roboto text-horizen-white-1 font-bold text-lg lg:text-xl uppercase tracking-widest text-center h-7 max-w-[200px]">
                {text}
            </span>
        </div>
    )
}

const IndexNumbers = () => {
    return (
        <section className="pt-32 lg:pt-0 relative lg:h-[940px] flex 2xl:max-w-screen-xl mx-auto">
            <img
                src={bgImg}
                alt="Zencon lounge"
                className="hidden lg:block h-[940px] w-full absolute object-fill mx-auto"
            />
            <div className="my-auto w-full shrink-0 relative z-10">
                <ContentContainer>
                    <Typography.H2>ZenCon0 By the Numbers</Typography.H2>

                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 mt-12">
                        <Box number="49" text="Speakers" />
                        <Box number="79" text="Talks and Sessions" />
                        <Box number="4" text="Tracks" />
                        <Box number="200+" text="Guests" />
                    </div>
                </ContentContainer>
            </div>
        </section>
    )
}

export default IndexNumbers
