/* eslint-disable react/jsx-props-no-spreading */
import React from "react"

const CarrouselArrowLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1.31134e-06 9L15 0.339747L15 17.6603L1.31134e-06 9Z" />
        </svg>
    )
}

const CarrouselArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = (
    props
) => {
    return (
        <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" />
        </svg>
    )
}

export { CarrouselArrowLeft, CarrouselArrowRight }
