import React from "react"

const TelegramLogo = () => {
    return (
        <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5552 0.0287884L0.305243 8.22184C0.217641 8.25411 0.14174 8.31186 0.0873474 8.38774C0.0329545 8.46361 0.00259272 8.55406 0.000158674 8.64738C-0.00227538 8.7407 0.0233421 8.83263 0.073706 8.91123C0.12407 8.98984 0.196859 9.05151 0.28266 9.0883L5.55826 11.3492L8.07096 18.3981C8.09699 18.4709 8.14486 18.5339 8.20804 18.5785C8.27123 18.623 8.34664 18.647 8.42396 18.6471H8.44175C8.51596 18.6416 8.58686 18.6141 8.64536 18.5681L8.64396 18.5754L8.66004 18.5625L8.66254 18.5604L12.6584 15.343L10.0164 13.4483L18.3788 19.4455C18.4416 19.4906 18.5145 19.5193 18.5912 19.5291C18.6678 19.5389 18.7457 19.5296 18.8178 19.5019C18.89 19.4742 18.9541 19.429 19.0045 19.3703C19.0548 19.3117 19.0898 19.2415 19.1063 19.166L23.1722 0.56602C23.1901 0.484304 23.1857 0.399275 23.1594 0.319865C23.1331 0.240455 23.0859 0.169591 23.0229 0.114665C22.9598 0.0597389 22.8831 0.0227728 22.8008 0.00767028C22.7185 -0.00743227 22.6337 -0.000150245 22.5552 0.0287884ZM8.36054 16.9781L6.27177 11.1193L18.6061 3.56242L9.42014 12.9507C9.3737 12.9986 9.34072 13.0579 9.32447 13.1226L8.36054 16.9781Z"
                fill="white"
            />
        </svg>
    )
}

export default TelegramLogo
