import React from "react"

const logo = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 400 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_27_6)">
                <path
                    d="M93.0015 74.8216C85.04 74.8216 78.5969 72.4369 73.6732 67.6651C68.749 62.8933 66.2869 56.6851 66.2869 49.037V47.6883C66.2869 42.3546 67.2702 37.6473 69.2369 33.5667C71.203 29.4859 74.0727 26.3293 77.8459 24.0958C81.618 21.8633 86.0949 20.7466 91.2747 20.7466C98.5651 20.7466 104.32 23.0194 108.541 27.5663C112.761 32.1131 114.872 38.4505 114.872 46.5797V52.8929H82.7379C83.313 55.8176 84.576 58.1147 86.5266 59.7853C88.4766 61.4559 91.0025 62.2913 94.1046 62.2913C99.22 62.2913 103.217 60.4926 106.095 56.893L113.481 65.6648C111.466 68.4603 108.613 70.6858 104.92 72.3403C101.227 73.9948 97.2533 74.822 93.0013 74.822L93.0015 74.8216ZM91.1789 33.277C86.4464 33.277 83.6324 36.4268 82.738 42.7238H99.0445V41.4709C99.1082 38.8681 98.4526 36.8525 97.0783 35.4219C95.7029 33.9924 93.7368 33.2771 91.1788 33.2771L91.1789 33.277Z"
                    fill="white"
                />
                <path
                    d="M136.215 21.7097L136.743 27.8313C140.323 23.108 145.263 20.7463 151.562 20.7463C156.965 20.7463 161.002 22.3685 163.672 25.6139C166.342 28.8593 167.725 33.7431 167.821 40.2651V73.8582H151.61V40.94C151.61 38.3061 151.083 36.3701 150.028 35.1322C148.972 33.8965 147.054 33.277 144.272 33.277C141.107 33.277 138.757 34.5299 137.222 37.0359V73.8583H121.059V21.7097H136.215V21.7097Z"
                    fill="white"
                />
                <path
                    d="M234.007 50.0972C233.783 54.982 232.473 59.2959 230.074 63.0385C227.676 66.7812 224.311 69.6817 219.978 71.7377C215.645 73.7948 210.698 74.8216 205.134 74.8216C195.958 74.8216 188.732 71.8184 183.456 65.8098C178.18 59.8012 175.543 51.3189 175.543 40.362V36.8916C175.543 30.0164 176.733 23.9998 179.115 18.8426C181.497 13.6855 184.927 9.70167 189.403 6.89005C193.88 4.07842 199.059 2.67197 204.943 2.67197C213.415 2.67197 220.226 4.91366 225.374 9.39588C230.522 13.8781 233.447 20.0552 234.151 27.9271H217.364C217.236 23.6549 216.165 20.5859 214.151 18.7215C212.137 16.8583 209.067 15.926 204.943 15.926C200.754 15.926 197.684 17.5008 195.734 20.6494C193.784 23.7991 192.761 28.827 192.665 35.7355V40.6988C192.665 48.1866 193.6 53.5364 195.47 56.7484C197.341 59.9615 200.561 61.5687 205.134 61.5687C209.003 61.5687 211.961 60.6526 214.007 58.8206C216.053 56.9896 217.141 54.0823 217.269 50.0972H234.007H234.007Z"
                    fill="white"
                />
                <path
                    d="M239.187 47.3017C239.187 42.0971 240.194 37.4627 242.208 33.3981C244.222 29.3336 247.116 26.2093 250.889 24.0229C254.662 21.8388 259.091 20.7463 264.174 20.7463C271.944 20.7463 278.067 23.1646 282.543 27.9998C287.02 32.8362 289.258 39.4148 289.258 47.7354V48.3146C289.258 56.4438 287.011 62.8931 282.52 67.665C278.027 72.4368 271.944 74.8215 264.27 74.8215C256.884 74.8215 250.953 72.5971 246.477 68.1472C242 63.6973 239.586 57.6644 239.235 50.0498L239.187 47.3017V47.3017ZM255.349 48.3146C255.349 53.1337 256.1 56.6687 257.604 58.9173C259.107 61.167 261.328 62.2908 264.27 62.2908C270.026 62.2908 272.967 57.8409 273.095 48.941V47.3015C273.095 37.9517 270.122 33.2768 264.174 33.2768C258.771 33.2768 255.845 37.309 255.398 45.3736L255.35 48.3145L255.349 48.3146Z"
                    fill="white"
                />
                <path
                    d="M311.225 21.7097L311.752 27.8313C315.333 23.108 320.273 20.7463 326.572 20.7463C331.975 20.7463 336.012 22.3685 338.682 25.6139C341.351 28.8593 342.735 33.7431 342.831 40.2651V73.8582H326.62V40.94C326.62 38.3061 326.092 36.3701 325.037 35.1322C323.982 33.8965 322.064 33.277 319.282 33.277C316.117 33.277 313.766 34.5299 312.231 37.0359V73.8583H296.068V21.7097H311.225V21.7097Z"
                    fill="white"
                />
                <path
                    d="M0.478394 73.8583L48.8431 3.68501H68.2326L19.8679 73.8583H0.478394Z"
                    fill="white"
                />
                <path
                    d="M34.0935 60.8454L25.6896 73.8583H61.0114V60.8454H34.0935Z"
                    fill="white"
                />
                <path
                    d="M29.4066 3.68501V16.6979H41.8119L50.2157 3.68501H29.4066Z"
                    fill="white"
                />
                <path
                    d="M14.3902 20.3829C19.9913 20.3829 24.5319 15.82 24.5319 10.1915C24.5319 4.56287 19.9913 0 14.3902 0C8.78903 0 4.24841 4.56287 4.24841 10.1915C4.24841 15.82 8.78903 20.3829 14.3902 20.3829Z"
                    fill="#62C5F8"
                />
                <path
                    d="M383.781 28.6739L395.012 12.3782C395.004 12.3655 394.994 12.3543 394.986 12.3416C394.556 11.6959 394.106 11.0701 393.621 10.48C392.661 9.31141 391.598 8.29393 390.46 7.38939C386.559 4.28936 381.626 2.72038 375.636 2.72038C367.898 2.72038 361.911 5.29085 357.675 10.4317C353.438 15.5726 351.32 22.8822 351.32 32.3608V45.1809C351.32 51.128 352.173 56.2237 353.87 60.4782C354.81 62.8348 356.018 64.9242 357.479 66.7615C357.488 66.7731 357.496 66.7864 357.505 66.7981L367.779 51.8913L383.781 28.6738L383.781 28.6739ZM367.531 29.228C367.627 24.5368 368.306 21.0347 369.569 18.7213C370.832 16.408 372.854 15.2513 375.636 15.2513C378.482 15.2513 380.56 16.4963 381.871 18.9864C381.955 19.1469 382.014 19.3587 382.093 19.5297L367.531 40.6582V29.228H367.531Z"
                    fill="white"
                />
                <path
                    d="M397.142 16.3854L383.837 35.6896V47.6389C383.805 52.6834 383.134 56.3863 381.823 58.7479C380.512 61.1096 378.481 62.2904 375.732 62.2904C372.886 62.2904 370.808 61.0773 369.497 58.6516C369.285 58.2593 369.118 57.7697 368.94 57.3036L360.373 69.734C364.353 73.1057 369.459 74.8212 375.732 74.8212C383.437 74.8212 389.409 72.2508 393.645 67.1097C397.882 61.9688 400 54.6592 400 45.1808V32.4088C400 26.1102 399.033 20.7837 397.142 16.3852V16.3854Z"
                    fill="white"
                />
                <path
                    d="M9.4967 108.692H2.02491V114.756H0V101.184H2.02491V106.881H9.49658V101.184H11.5215V114.756H9.49658V108.692H9.4967Z"
                    fill="white"
                />
                <path
                    d="M25.6181 100.96C29.6476 100.96 32.4221 103.952 32.4221 107.98C32.4221 112.009 29.6477 115 25.6181 115C21.5885 115 18.8153 112.009 18.8153 107.98C18.8153 103.952 21.5886 100.96 25.6181 100.96V100.96ZM25.6181 102.771C22.7224 102.771 20.9004 104.99 20.9004 107.98C20.9004 110.951 22.7223 113.189 25.6181 113.189C28.473 113.189 30.337 110.951 30.337 107.98C30.337 104.99 28.473 102.771 25.6181 102.771Z"
                    fill="white"
                />
                <path
                    d="M44.2918 109.526H41.7611V114.756H39.7362V101.184H45.4256C47.997 101.184 49.759 102.852 49.759 105.355C49.759 107.797 48.1183 109.099 46.3973 109.324L49.8598 114.756H47.5117L44.2918 109.526V109.526ZM45.1625 102.974H41.7611V107.736H45.1625C46.6206 107.736 47.6727 106.779 47.6727 105.355C47.6727 103.931 46.6206 102.974 45.1625 102.974Z"
                    fill="white"
                />
                <path
                    d="M57.1128 101.184H59.1377V114.756H57.1128V101.184Z"
                    fill="white"
                />
                <path
                    d="M66.4496 113.088L73.698 102.974H66.4496V101.184H76.3103V102.852L69.0608 112.965H76.452V114.756H66.4497V113.088L66.4496 113.088Z"
                    fill="white"
                />
                <path
                    d="M83.7649 101.184H92.8364V102.974H85.7899V106.943H92.6948V108.734H85.7899V112.965H92.8364V114.756H83.7649V101.184V101.184Z"
                    fill="white"
                />
                <path
                    d="M102.255 104.338V114.756H100.23V101.184H102.315L109.665 111.357V101.184H111.69V114.756H109.727L102.255 104.338H102.255Z"
                    fill="white"
                />
                <path
                    d="M129.637 101.184H138.709V102.974H131.662V106.943H138.567V108.734H131.662V112.965H138.709V114.756H129.637V101.184V101.184Z"
                    fill="white"
                />
                <path
                    d="M145.535 107.98C145.535 103.809 148.612 100.96 152.459 100.96C155.112 100.96 156.772 102.241 157.826 103.83L156.105 104.745C155.396 103.605 154.059 102.771 152.459 102.771C149.726 102.771 147.62 104.928 147.62 107.98C147.62 111.012 149.726 113.189 152.459 113.189C154.059 113.189 155.396 112.355 156.105 111.215L157.826 112.131C156.753 113.718 155.112 115 152.459 115C148.612 115 145.535 112.152 145.535 107.98V107.98Z"
                    fill="white"
                />
                <path
                    d="M170.951 100.96C174.98 100.96 177.754 103.952 177.754 107.98C177.754 112.009 174.98 115 170.951 115C166.921 115 164.148 112.009 164.148 107.98C164.148 103.952 166.921 100.96 170.951 100.96ZM170.951 102.771C168.055 102.771 166.233 104.99 166.233 107.98C166.233 110.951 168.055 113.189 170.951 113.189C173.805 113.189 175.669 110.951 175.669 107.98C175.669 104.99 173.805 102.771 170.951 102.771Z"
                    fill="white"
                />
                <path
                    d="M185.392 111.276C186.303 112.294 187.761 113.189 189.644 113.189C191.832 113.189 192.6 112.071 192.6 111.093C192.6 109.669 191.062 109.262 189.341 108.814C187.174 108.245 184.663 107.613 184.663 104.846C184.663 102.608 186.627 100.98 189.442 100.98C191.528 100.98 193.167 101.652 194.362 102.873L193.188 104.379C192.155 103.279 190.737 102.792 189.279 102.792C187.782 102.792 186.749 103.564 186.749 104.703C186.749 105.925 188.207 106.311 189.887 106.738C192.095 107.328 194.666 108.021 194.666 110.911C194.666 113.026 193.208 115 189.563 115C187.154 115 185.392 114.125 184.237 112.843L185.392 111.276V111.276Z"
                    fill="white"
                />
                <path
                    d="M205.564 109.079L200.278 101.184H202.607L206.577 107.248L210.504 101.184H212.853L207.589 109.079V114.756H205.564V109.079V109.079Z"
                    fill="white"
                />
                <path
                    d="M219.255 111.276C220.166 112.294 221.624 113.189 223.508 113.189C225.695 113.189 226.463 112.071 226.463 111.093C226.463 109.669 224.925 109.262 223.204 108.814C221.037 108.245 218.526 107.613 218.526 104.846C218.526 102.608 220.491 100.98 223.305 100.98C225.391 100.98 227.03 101.652 228.225 102.873L227.051 104.379C226.018 103.279 224.601 102.792 223.143 102.792C221.645 102.792 220.612 103.564 220.612 104.703C220.612 105.925 222.07 106.311 223.75 106.738C225.958 107.328 228.529 108.021 228.529 110.911C228.529 113.026 227.071 115 223.426 115C221.017 115 219.255 114.125 218.101 112.843L219.255 111.276V111.276Z"
                    fill="white"
                />
                <path
                    d="M238.88 102.974H234.688V101.184H245.076V102.974H240.905V114.756H238.88V102.974Z"
                    fill="white"
                />
                <path
                    d="M252.044 101.184H261.116V102.974H254.069V106.943H260.974V108.734H254.069V112.965H261.116V114.756H252.044V101.184V101.184Z"
                    fill="white"
                />
                <path
                    d="M280.133 103.972L275.759 114.756H274.908L270.535 103.972V114.756H268.51V101.184H271.405L275.333 110.93L279.262 101.184H282.158V114.756H280.133V103.972H280.133Z"
                    fill="white"
                />
                <path
                    d="M300.428 111.276C301.339 112.294 302.797 113.189 304.681 113.189C306.868 113.189 307.636 112.071 307.636 111.093C307.636 109.669 306.098 109.262 304.377 108.814C302.21 108.245 299.699 107.613 299.699 104.846C299.699 102.608 301.664 100.98 304.478 100.98C306.564 100.98 308.203 101.652 309.398 102.873L308.224 104.379C307.191 103.279 305.774 102.792 304.316 102.792C302.818 102.792 301.785 103.564 301.785 104.703C301.785 105.925 303.243 106.311 304.923 106.738C307.131 107.328 309.702 108.021 309.702 110.911C309.702 113.026 308.244 115 304.599 115C302.19 115 300.428 114.125 299.274 112.843L300.428 111.276V111.276Z"
                    fill="white"
                />
                <path
                    d="M316.914 101.184H318.958V109.384C318.958 111.704 320.235 113.19 322.603 113.19C324.972 113.19 326.228 111.704 326.228 109.384V101.184H328.274V109.425C328.274 112.803 326.39 115 322.603 115C318.817 115 316.914 112.783 316.914 109.446V101.184H316.914Z"
                    fill="white"
                />
                <path
                    d="M347.776 103.972L343.402 114.756H342.552L338.179 103.972V114.756H336.154V101.184H339.048L342.977 110.93L346.906 101.184H349.801V114.756H347.776V103.972H347.776Z"
                    fill="white"
                />
                <path
                    d="M369.284 103.972L364.91 114.756H364.06L359.687 103.972V114.756H357.662V101.184H360.556L364.485 110.93L368.414 101.184H371.309V114.756H369.284V103.972H369.284Z"
                    fill="white"
                />
                <path
                    d="M379.17 101.184H381.195V114.756H379.17V101.184Z"
                    fill="white"
                />
                <path
                    d="M392.374 102.974H388.182V101.184H398.57V102.974H394.399V114.756H392.374V102.974V102.974Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_27_6">
                    <rect width="400" height="115" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default logo
