import React from "react"

const ContentContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className="px-6 md:px-12 lg:px-8 xl:px-12 max-w-screen-xl mx-auto w-full">
            {children}
        </div>
    )
}

export default ContentContainer
