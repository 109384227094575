import React from "react"

const ZModalCloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="37"
            height="38"
            viewBox="0 0 37 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <line
                x1="1.67757"
                y1="1.26347"
                x2="35.6187"
                y2="35.2046"
                stroke="#62C5F8"
                strokeWidth="2"
            />
            <line
                x1="35.6187"
                y1="2.67769"
                x2="1.6776"
                y2="36.6188"
                stroke="#62C5F8"
                strokeWidth="2"
            />
        </svg>
    )
}

export default ZModalCloseIcon
