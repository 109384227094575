import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import Typography from "../Typography"
import HorizenLabsLogo from "./HorizenLabsLogo"
import DiscordLogo from "./SocialButtons/DiscordLogo"
import LinkedInLogo from "./SocialButtons/LinkedInLogo"
import SocialButton from "./SocialButtons/SocialButton"
import TelegramLogo from "./SocialButtons/TelegramLogo"
import TwitterLogo from "./SocialButtons/TwitterLogo"

const Footer = () => {
    return (
        <footer className="text-white bg-horizen-bg lg:[background:radial-gradient(112.22%_451.36%_at_-6.25%_-11.22%,_#6E16DA_0%,_#5C15BE_16.56%,_#020E30_59.37%)]">
            <ContentContainer>
                <div className="py-[190px] pb-[100px] flex flex-col lg:flex-row justify-between">
                    <div className="grid grid-cols-2 gap-x-20 lg:gap-x-48">
                        <div className="flex flex-col gap-y-3 lg:gap-y-0">
                            <Typography.Link1 href="mailto:events@horizen.io">
                                Contact Us
                            </Typography.Link1>
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-end mt-10 lg:mt-0 gap-y-4">
                        <div className="flex w-full pb-8 gap-6 lg:pb-6 justify-center">
                            <SocialButton
                                alt="Twitter logo"
                                icon={<TwitterLogo />}
                                href="https://twitter.com/HorizenLabs"
                            />
                            <SocialButton
                                alt="LinkedIn logo"
                                icon={<LinkedInLogo />}
                                href="https://www.linkedin.com/company/horizenlabs/"
                            />
                            <SocialButton
                                alt="Telegram logo"
                                icon={<TelegramLogo />}
                                href="https://t.me/horizencommunity"
                            />
                            <SocialButton
                                alt="Discord logo"
                                icon={<DiscordLogo />}
                                href="https://discord.com/invite/z8eebsj7Sv"
                            />
                        </div>

                        <Typography.Link1
                            target="_blank"
                            href="https://horizenlabs.io/"
                            aria-label="Horizen Labs logo"
                        >
                            <HorizenLabsLogo />
                        </Typography.Link1>
                    </div>
                </div>
            </ContentContainer>
        </footer>
    )
}

export default Footer
