import React from "react"

interface YoutubeVideoProps {
    videoId: string
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ videoId }) => {
    return (
        <div className="pb-[56.25%] relative h-0 bg-horizen-purple">
            <iframe
                className="absolute top-0 left-0 h-full w-full"
                src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    )
}

export default YoutubeVideo
