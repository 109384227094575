import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import YoutubeVideo from "../YoutubeVideo"

const InedexMainVideo = () => {
    return (
        <section className="pt-24 lg:pt-0 lg:-mt-[150px]">
            <ContentContainer>
                <YoutubeVideo videoId="UqMIFHeAY6I" />
            </ContentContainer>
        </section>
    )
}

export default InedexMainVideo
