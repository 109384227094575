import React from "react"
import LoadingBullets from "./LoadingBullets"

interface ButtonSolidProps
    extends React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    loading?: boolean
}

const ButtonSolid: React.FC<ButtonSolidProps> = ({
    children,
    className = "",
    loading = false,
    onClick,
    ...rest
}) => {
    const handleOnClick = (
        evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        if (loading) {
            evt.preventDefault()
            return
        }
        if (onClick) onClick(evt)
    }

    return (
        <a
            target="_blank"
            rel="noreferrer"
            aria-hidden
            className={`transition-colors font-roboto flex justify-center items-center bg-horizen-blue-2 hover:bg-[#3F9DE3] text-base leading-[18.75px] font-bold text-white text-center rounded-[10px] px-3 min-w-[180px] h-[47px] cursor-pointer ${className} ${
                loading ? "cursor-not-allowed hover:bg-horizen-blue-2" : ""
            }`}
            onClick={handleOnClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {loading ? <LoadingBullets /> : children}
        </a>
    )
}

export default ButtonSolid
