import React from "react"

const ZenConLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="168"
            height="48"
            viewBox="0 0 168 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <g clipPath="url(#clip0_381_191)">
                <path
                    d="M85.4406 43.0851C85.4406 43.9264 85.2487 44.6022 84.8649 45.1127C84.4812 45.6231 83.9661 45.8784 83.3197 45.8784C82.6294 45.8784 82.0959 45.6324 81.7187 45.1406L81.6734 45.7766H80.8149V37.9616H81.749V40.877C82.1262 40.4056 82.6462 40.1698 83.3096 40.1698C83.9727 40.1698 84.4937 40.4224 84.8726 40.9279C85.2512 41.4334 85.4407 42.1253 85.4407 43.0037V43.0852L85.4406 43.0851ZM84.5062 42.9783C84.5062 42.3372 84.3833 41.8419 84.1377 41.4927C83.8919 41.1433 83.5384 40.9686 83.0773 40.9686C82.4612 40.9686 82.0185 41.2568 81.749 41.8335V44.2147C82.0352 44.7914 82.4814 45.0796 83.0873 45.0796C83.5349 45.0796 83.8835 44.9049 84.1325 44.5555C84.3819 44.2062 84.5062 43.6804 84.5062 42.9783Z"
                    fill="white"
                />
                <path
                    d="M88.6634 44.3978L89.9359 40.2715H90.9358L88.739 46.6263C88.399 47.5421 87.8586 48 87.1181 48L86.9412 47.9847L86.5929 47.9186V47.1554L86.8454 47.1758C87.1616 47.1758 87.4085 47.1114 87.5851 46.9824C87.7621 46.8535 87.9076 46.6179 88.0218 46.2752L88.229 45.7155L86.2798 40.2715H87.2999L88.6633 44.3978H88.6634Z"
                    fill="white"
                />
                <path
                    d="M100.655 45.7766H98.9293V42.7484H96.3737V45.7766H94.6387V38.5247H96.3737V41.4036H98.9293V38.5247H100.655V45.7766Z"
                    fill="white"
                />
                <path
                    d="M108.1 42.3002C108.1 43.0074 107.97 43.6332 107.71 44.1778C107.45 44.7224 107.08 45.1415 106.6 45.4354C106.121 45.7292 105.574 45.8763 104.962 45.8763C104.349 45.8763 103.805 45.7343 103.33 45.4505C102.856 45.1664 102.486 44.7606 102.22 44.2325C101.955 43.7046 101.816 43.0988 101.803 42.4146V42.0064C101.803 41.2958 101.932 40.6689 102.191 40.1261C102.449 39.5831 102.82 39.164 103.303 38.8685C103.786 38.5729 104.335 38.4251 104.952 38.4251C105.561 38.4251 106.105 38.5713 106.583 38.8634C107.061 39.1557 107.433 39.5715 107.698 40.1112C107.963 40.6507 108.097 41.2691 108.101 41.9663V42.3002L108.1 42.3002ZM106.336 41.9963C106.336 41.2757 106.216 40.7287 105.977 40.3552C105.738 39.9815 105.396 39.7948 104.952 39.7948C104.081 39.7948 103.622 40.4522 103.572 41.7671L103.567 42.3001C103.567 43.0107 103.684 43.5568 103.918 43.9386C104.152 44.3205 104.5 44.5114 104.961 44.5114C105.4 44.5114 105.737 44.3239 105.975 43.9486C106.212 43.5735 106.332 43.0339 106.336 42.33V41.9963Z"
                    fill="white"
                />
                <path
                    d="M111.923 43.2116H110.984V45.7766H109.249V38.5248H112.081C112.935 38.5248 113.602 38.7155 114.083 39.0969C114.564 39.4783 114.805 40.0174 114.805 40.714C114.805 41.218 114.704 41.6359 114.501 41.9677C114.298 42.2995 113.981 42.5681 113.549 42.7737L115.052 45.7019V45.7766H113.193L111.923 43.2116ZM110.984 41.8668H112.081C112.411 41.8668 112.659 41.7794 112.825 41.6047C112.992 41.4298 113.075 41.1861 113.075 40.8731C113.075 40.5602 112.991 40.3146 112.823 40.1368C112.655 39.9587 112.407 39.8695 112.081 39.8695H110.984V41.8668Z"
                    fill="white"
                />
                <path
                    d="M117.955 45.7766H116.225V38.5247H117.955V45.7766Z"
                    fill="white"
                />
                <path
                    d="M121.362 44.4318H124.803V45.7766H119.197V44.8552L122.627 39.8745H119.177V38.5248H124.768V39.4213L121.362 44.4319L121.362 44.4318Z"
                    fill="white"
                />
                <path
                    d="M130.306 42.6986H127.577V44.4318H130.8V45.7766H125.842V38.5247H130.81V39.8744H127.577V41.4036H130.306V42.6986Z"
                    fill="white"
                />
                <path
                    d="M137.772 45.7766H136.046L133.495 41.2642V45.7766H131.76V38.5247H133.495L136.041 43.0372V38.5247H137.772V45.7766Z"
                    fill="white"
                />
                <path
                    d="M143.631 44.4318H146.652V45.7766H141.896V38.5248H143.631V44.4318H143.631Z"
                    fill="white"
                />
                <path
                    d="M151.818 44.4218H149.441L149.026 45.7766H147.172L149.812 38.5247H151.443L154.102 45.7766H152.239L151.818 44.4218ZM149.856 43.0721H151.403L150.627 40.5568L149.856 43.0721Z"
                    fill="white"
                />
                <path
                    d="M154.88 45.7766V38.5247H157.465C158.391 38.5247 159.096 38.6958 159.578 39.0377C160.061 39.3797 160.303 39.8762 160.303 40.5269C160.303 40.9022 160.217 41.2225 160.046 41.4882C159.874 41.7538 159.622 41.9498 159.289 42.0759C159.665 42.1756 159.953 42.3615 160.154 42.6339C160.355 42.9062 160.456 43.2381 160.456 43.6298C160.456 44.3406 160.233 44.8743 159.786 45.2311C159.339 45.5881 158.678 45.7699 157.802 45.7766H154.88L154.88 45.7766ZM156.615 41.548H157.519C157.899 41.5447 158.169 41.4747 158.33 41.3382C158.492 41.2017 158.573 41.0001 158.573 40.7338C158.573 40.4273 158.485 40.2068 158.311 40.0719C158.136 39.937 157.854 39.8696 157.465 39.8696H156.615V41.5481V41.548ZM156.615 42.6935V44.4318H157.752C158.065 44.4318 158.305 44.3595 158.474 44.2151C158.642 44.0707 158.726 43.8674 158.726 43.6049C158.726 43.0007 158.427 42.6968 157.831 42.6935H156.615V42.6935Z"
                    fill="white"
                />
                <path
                    d="M165.375 43.849C165.375 43.5934 165.286 43.3942 165.106 43.2514C164.927 43.1086 164.611 42.9609 164.159 42.8081C163.708 42.6554 163.339 42.5077 163.052 42.3649C162.12 41.9033 161.653 41.2691 161.653 40.4622C161.653 40.0604 161.769 39.7061 162.001 39.3989C162.234 39.0918 162.563 38.8527 162.988 38.6817C163.413 38.5106 163.891 38.4251 164.422 38.4251C164.939 38.4251 165.403 38.5181 165.813 38.7041C166.224 38.89 166.542 39.1548 166.77 39.4985C166.997 39.8422 167.111 40.2348 167.111 40.6765H165.38C165.38 40.3808 165.291 40.1519 165.111 39.9891C164.931 39.8264 164.688 39.745 164.382 39.745C164.072 39.745 163.827 39.814 163.648 39.9517C163.468 40.0897 163.379 40.2647 163.379 40.4772C163.379 40.6632 163.477 40.8318 163.675 40.9828C163.873 41.1339 164.22 41.2899 164.718 41.4509C165.216 41.612 165.624 41.7855 165.944 41.9715C166.722 42.4231 167.111 43.0458 167.111 43.8393C167.111 44.4735 166.873 44.9716 166.399 45.3334C165.924 45.6953 165.273 45.8764 164.446 45.8764C163.863 45.8764 163.335 45.7709 162.862 45.56C162.389 45.3492 162.033 45.0604 161.794 44.6934C161.555 44.3265 161.436 43.904 161.436 43.4258H163.176C163.176 43.8144 163.275 44.1007 163.475 44.2851C163.674 44.4693 163.998 44.5614 164.446 44.5614C164.733 44.5614 164.959 44.4992 165.126 44.3747C165.292 44.2502 165.375 44.0751 165.375 43.8491V43.849Z"
                    fill="white"
                />
                <path
                    d="M38.9064 31.545C35.5586 31.545 32.8492 30.5396 30.7788 28.5278C28.7082 26.516 27.6729 23.8986 27.6729 20.6742V20.1055C27.6729 17.8568 28.0863 15.8723 28.9134 14.1518C29.7402 12.4313 30.9469 11.1005 32.5335 10.1588C34.1197 9.21758 36.0023 8.74673 38.1804 8.74673C41.246 8.74673 43.6659 9.70496 45.4409 11.622C47.2156 13.5389 48.1029 16.2108 48.1029 19.6381V22.2997H34.5906C34.8325 23.5328 35.3636 24.5012 36.1838 25.2056C37.0038 25.9099 38.0659 26.2621 39.3704 26.2621C41.5214 26.2621 43.2022 25.5037 44.4123 23.9862L47.518 27.6844C46.6709 28.8629 45.471 29.8012 43.9181 30.4987C42.3653 31.1962 40.6944 31.545 38.9064 31.545H38.9064ZM38.14 14.0297C36.15 14.0297 34.9667 15.3576 34.5906 18.0125H41.4475V17.4843C41.4744 16.3869 41.1987 15.5372 40.6208 14.934C40.0424 14.3313 39.2157 14.0298 38.14 14.0298V14.0297Z"
                    fill="white"
                />
                <path
                    d="M57.0778 9.15289L57.2996 11.7338C58.8052 9.74242 60.8826 8.74673 63.5313 8.74673C65.8033 8.74673 67.501 9.43065 68.6237 10.7989C69.7462 12.1672 70.3279 14.2262 70.3682 16.9759V31.1389H63.5516V17.2605C63.5516 16.15 63.3298 15.3338 62.8861 14.8118C62.4422 14.2909 61.6357 14.0297 60.4659 14.0297C59.1349 14.0297 58.1466 14.5579 57.5012 15.6144V31.1389H50.7046V9.15289H57.0778Z"
                    fill="white"
                />
                <path
                    d="M98.1996 21.1211C98.1054 23.1806 97.5543 24.9993 96.5458 26.5772C95.5375 28.1552 94.1223 29.378 92.3005 30.2448C90.4785 31.1121 88.3982 31.545 86.0586 31.545C82.1998 31.545 79.1614 30.2789 76.9428 27.7456C74.7244 25.2124 73.6152 21.6363 73.6152 17.0168V15.5536C73.6152 12.6551 74.1159 10.1184 75.1176 7.94414C76.1192 5.76986 77.5613 4.09026 79.4436 2.90488C81.326 1.71949 83.504 1.12653 85.978 1.12653C89.5407 1.12653 92.4046 2.07162 94.5696 3.96135C96.7341 5.85108 97.9643 8.45534 98.2602 11.7741H91.2015C91.1476 9.97293 90.6972 8.67906 89.8501 7.89304C89.0031 7.10748 87.7124 6.71445 85.978 6.71445C84.2166 6.71445 82.9259 7.37839 82.1059 8.70582C81.2857 10.0337 80.8555 12.1535 80.8152 15.0662V17.1587C80.8152 20.3156 81.2084 22.571 81.9949 23.9252C82.7816 25.2799 84.1358 25.9575 86.0587 25.9575C87.6855 25.9575 88.9293 25.5713 89.7899 24.7988C90.6502 24.0269 91.1074 22.8011 91.1612 21.1211H98.1997L98.1996 21.1211Z"
                    fill="white"
                />
                <path
                    d="M100.378 19.9425C100.378 17.7483 100.801 15.7944 101.648 14.0808C102.495 12.3672 103.712 11.0499 105.299 10.1281C106.885 9.20735 108.747 8.74673 110.885 8.74673C114.152 8.74673 116.727 9.76625 118.609 11.8048C120.492 13.8438 121.433 16.6174 121.433 20.1254V20.3696C121.433 23.7969 120.488 26.516 118.6 28.5278C116.71 30.5396 114.152 31.545 110.925 31.545C107.82 31.545 105.325 30.6072 103.443 28.7311C101.561 26.855 100.546 24.3115 100.398 21.1012L100.378 19.9426L100.378 19.9425ZM107.174 20.3696C107.174 22.4014 107.49 23.8917 108.122 24.8398C108.754 25.7883 109.688 26.262 110.926 26.262C113.346 26.262 114.583 24.3859 114.636 20.6337V19.9425C114.636 16.0006 113.386 14.0296 110.885 14.0296C108.613 14.0296 107.383 15.7297 107.195 19.1297L107.174 20.3696V20.3696Z"
                    fill="white"
                />
                <path
                    d="M130.67 9.15289L130.892 11.7338C132.397 9.74242 134.475 8.74673 137.124 8.74673C139.396 8.74673 141.093 9.43065 142.216 10.7989C143.338 12.1672 143.92 14.2262 143.961 16.9759V31.1389H137.144V17.2605C137.144 16.15 136.922 15.3338 136.478 14.8118C136.034 14.2909 135.228 14.0297 134.058 14.0297C132.727 14.0297 131.739 14.5579 131.093 15.6144V31.1389H124.297V9.15289H130.67Z"
                    fill="white"
                />
                <path
                    d="M12.1644 1.55362V7.03989H16.5661L0 31.1388H8.15336L28.4908 1.55362H12.1644Z"
                    fill="white"
                />
                <path
                    d="M10.3481 31.1388H25.4544V25.6526H14.1195L10.3481 31.1388Z"
                    fill="white"
                />
                <path
                    d="M5.85007 8.59346C8.20536 8.59346 10.1147 6.66975 10.1147 4.29673C10.1147 1.92371 8.20536 0 5.85007 0C3.49479 0 1.58545 1.92371 1.58545 4.29673C1.58545 6.66975 3.49479 8.59346 5.85007 8.59346Z"
                    fill="#62C5F8"
                />
                <path
                    d="M161.18 12.089L165.903 5.2187C165.899 5.21335 165.895 5.20859 165.891 5.20329C165.711 4.9311 165.522 4.66723 165.318 4.41841C164.914 3.92577 164.467 3.49676 163.988 3.11541C162.348 1.80846 160.274 1.14697 157.755 1.14697C154.501 1.14697 151.983 2.23067 150.202 4.39806C148.421 6.5655 147.53 9.64724 147.53 13.6434V19.0485C147.53 21.5558 147.889 23.7041 148.602 25.4978C148.997 26.4914 149.505 27.3723 150.119 28.1469C150.123 28.1518 150.127 28.1574 150.131 28.1623L154.451 21.8776L161.18 12.089L161.18 12.089ZM154.346 12.3226C154.387 10.3448 154.672 8.86831 155.204 7.89297C155.735 6.91763 156.585 6.43 157.755 6.43C158.951 6.43 159.825 6.95488 160.377 8.00472C160.412 8.07243 160.437 8.16167 160.47 8.2338L154.346 17.1416V12.3226H154.346Z"
                    fill="white"
                />
                <path
                    d="M166.798 6.90811L161.204 15.0468V20.0847C161.19 22.2115 160.908 23.7726 160.357 24.7682C159.805 25.764 158.952 26.2618 157.795 26.2618C156.599 26.2618 155.725 25.7503 155.174 24.7277C155.084 24.5623 155.014 24.3558 154.939 24.1594L151.337 29.4001C153.011 30.8216 155.158 31.5449 157.795 31.5449C161.035 31.5449 163.546 30.4612 165.328 28.2937C167.109 26.1262 168 23.0445 168 19.0484V13.6637C168 11.0082 167.593 8.7625 166.798 6.90811Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_381_191">
                    <rect width="168" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ZenConLogo
