import React from "react"

const HorizenLabsLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="124"
            height="29"
            viewBox="0 0 124 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M13.4481 0.18634V5.66981C15.1239 5.3873 16.8217 5.24556 18.5226 5.24618C19.5291 5.24618 20.524 5.29363 21.5072 5.38852V0.18634H13.4481Z"
                fill="white"
            />
            <path
                d="M13.4481 15.1964V28.8508L21.5072 23.6385V14.7524C20.5377 14.612 19.5588 14.5418 18.5785 14.5423C16.8464 14.5441 15.1219 14.7639 13.4481 15.1964V15.1964Z"
                fill="white"
            />
            <path
                d="M0.0141602 23.6317L8.07325 28.8441V15.7657C4.69836 17.6272 1.91176 20.347 0.0141602 23.6317V23.6317Z"
                fill="#0D6284"
            />
            <path
                d="M8.07325 0.18634H0.0141602V9.88916C2.46841 8.09726 5.18469 6.67058 8.07325 5.65625V0.18634Z"
                fill="white"
            />
            <path
                d="M18.5226 5.24619C11.778 5.23579 5.236 7.48131 0 11.604V23.6317C1.90142 20.3452 4.69304 17.6252 8.07307 15.7658C12.1465 13.5254 16.8714 12.6588 21.5072 13.3019V5.38853C20.5263 5.29364 19.5315 5.24619 18.5226 5.24619Z"
                fill="#62C6F9"
            />
            <path
                d="M31.2018 28.8441V20.7104H32.6731V27.5901H36.3741V28.8475L31.2018 28.8441Z"
                fill="white"
            />
            <path
                d="M51.3704 28.8441L50.7553 27.2817H46.9109L46.2924 28.8441H44.6218L47.9175 20.7104H49.7522L53.0479 28.8441H51.3704ZM48.8296 22.1473L47.3233 26.0413H50.3394L48.8296 22.1473Z"
                fill="white"
            />
            <path
                d="M61.5229 28.8441V20.7104H65.6608C67.1951 20.7104 68.0513 21.639 68.0513 22.7845C68.0721 23.212 67.9372 23.6331 67.6701 23.9739C67.4031 24.3147 67.0208 24.5535 66.5905 24.6485C67.0587 24.7481 67.4767 25.0022 67.7731 25.3676C68.0695 25.7329 68.2259 26.1867 68.2155 26.6514C68.2155 27.9053 67.3453 28.8475 65.7692 28.8475L61.5229 28.8441ZM66.531 23.0251C66.536 22.8772 66.508 22.7299 66.4492 22.5933C66.3903 22.4568 66.3019 22.334 66.1899 22.2334C66.0779 22.1329 65.9449 22.0568 65.8 22.0104C65.6551 21.9639 65.5015 21.9482 65.3498 21.9643H62.9978V24.0723H65.3498C66.1047 24.0723 66.531 23.6351 66.531 23.0251V23.0251ZM66.6953 26.4514C66.6953 25.8414 66.2549 25.3297 65.4127 25.3297H62.9978V27.5867H65.4127C66.2165 27.5867 66.7093 27.1597 66.7093 26.4514H66.6953Z"
                fill="white"
            />
            <path
                d="M76.6556 27.6952L77.4699 26.5972C77.809 26.9515 78.22 27.2341 78.6771 27.4272C79.1342 27.6202 79.6275 27.7195 80.126 27.7189C81.3317 27.7189 81.8105 27.1462 81.8105 26.6107C81.8105 24.9162 76.9178 25.9634 76.9178 22.9641C76.9178 21.6085 78.1374 20.5918 79.9757 20.5918C80.5514 20.57 81.1257 20.6592 81.6656 20.854C82.2055 21.0489 82.7002 21.3457 83.1211 21.7271L82.2788 22.7879C81.6293 22.1787 80.7569 21.8447 79.8534 21.8593C78.9832 21.8593 78.431 22.2626 78.431 22.876C78.431 24.3875 83.3238 23.4487 83.3238 26.4853C83.3238 27.8409 82.3452 29 80.0806 29C78.5149 28.9898 77.3965 28.4645 76.6556 27.6952Z"
                fill="white"
            />
            <path
                d="M53.7785 7.50466e-07C52.2169 -0.000669602 50.6901 0.447767 49.3914 1.28859C48.0926 2.12942 47.0802 3.32487 46.4821 4.72373C45.884 6.12259 45.7272 7.66203 46.0314 9.14734C46.3356 10.6327 47.0873 11.9971 48.1912 13.0681C49.2952 14.1392 50.7019 14.8687 52.2335 15.1643C53.765 15.46 55.3526 15.3086 56.7954 14.7292C58.2382 14.1499 59.4714 13.1686 60.339 11.9095C61.2067 10.6505 61.6698 9.17017 61.6698 7.65584C61.6689 5.62625 60.8373 3.67999 59.3577 2.24453C57.878 0.809077 55.8714 0.00179665 53.7785 7.50466e-07V7.50466e-07ZM53.7785 12.3564C52.8188 12.3571 51.8804 12.0817 51.0822 11.5651C50.2839 11.0485 49.6616 10.3138 49.294 9.45416C48.9265 8.59448 48.8301 7.64838 49.0172 6.73558C49.2042 5.82278 49.6663 4.9843 50.3449 4.32623C51.0235 3.66816 51.8882 3.22008 52.8295 3.03869C53.7708 2.85729 54.7464 2.95073 55.6329 3.30718C56.5194 3.66364 57.277 4.26709 57.8097 5.04117C58.3425 5.81526 58.6265 6.72519 58.6258 7.65584C58.6249 8.90224 58.1139 10.0973 57.205 10.9787C56.2962 11.86 55.0638 12.3555 53.7785 12.3564V12.3564Z"
                fill="white"
            />
            <path
                d="M34.2563 14.9897H31.2018V0.18634H34.2563V6.11039H40.3548V0.18634H43.4092V14.9897H40.3548V9.0758H34.2563V14.9897Z"
                fill="white"
            />
            <path
                d="M81.9257 14.9897H78.8712V0.18634H81.9257V14.9897Z"
                fill="white"
            />
            <path
                d="M96.1988 14.9897H84.3862L90.9006 3.14836H84.3862V0.18634H96.1988L89.7089 12.0277H96.1988V14.9897Z"
                fill="white"
            />
            <path
                d="M109.102 14.9897H98.6382V0.18634H109.102V3.14836H101.693V6.11039H106.708V9.0758H101.693V12.0277H109.102V14.9897Z"
                fill="white"
            />
            <path
                d="M123.993 14.9897H120.729L114.84 5.24618V14.9897H111.793V0.18634H115.06L120.946 9.94V0.18634H124L123.993 14.9897Z"
                fill="white"
            />
            <path
                d="M76.3758 5.08012C76.3758 1.91814 74.4362 0.18634 71.2559 0.18634H64.116V14.9897H67.1704V9.97389H69.8545L72.9474 14.9897H76.3269L73.1816 9.71294C75.2051 9.12324 76.3758 7.53378 76.3758 5.08012ZM67.1704 4.87677V3.14836H70.819C72.1436 3.14836 73.2654 3.80584 73.2654 5.08012C73.2654 6.3544 72.1506 7.01187 70.819 7.01187H67.1704V4.87677V4.87677Z"
                fill="white"
            />
        </svg>
    )
}

export default HorizenLabsLogo
