import React from "react"
import ReactModal from "react-modal"
import ZModalCloseIcon from "../../assets/ZenCon/index/ZModalCloseIcon"
import "../../styles/ZenCon/ZModal.module.css"

export interface ZModalProps {
    isOpen: boolean
    onRequestClose: (
        event?:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
    ) => void
}

const ZModal: React.FC<ZModalProps> = ({
    isOpen,
    onRequestClose,
    children,
}) => {
    return (
        <>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                htmlOpenClassName="overflow-hidden"
                closeTimeoutMS={250}
                ariaHideApp={false}
            >
                <ZModalCloseIcon
                    className="transition-opacity absolute z-50 right-[18px] top-[18px] cursor-pointer hover:opacity-60"
                    onClick={onRequestClose}
                />

                {children}
            </ReactModal>
        </>
    )
}

export default ZModal
