/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import bgImg from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-speakers.png"
import IndexSpeakerCarrousel from "./indexSpeakers/indexSpeakerCarrousel"
import { speakers } from "./indexSpeakers/speakers"
import ContentContainer from "../../../templates/ContentContainer"
import Typography from "../../Typography"

const IndexSpeakersNew = () => {
    return (
        <section
            id="speakers-section"
            className="pt-32 lg:pt-0 2xl:max-w-screen-xl mx-auto relative z-0"
        >
            <div className="relative h-0 -mt-[250px] z-0 hidden lg:block">
                <img
                    className="object-contain max-w-[100vw] w-full"
                    src={bgImg}
                    alt="conference hall"
                />
            </div>
            <div className="relative z-10 lg:pt-[400px]">
                <ContentContainer>
                    <Typography.H2 className="mb-14">
                        ZenCon0 Speakers
                    </Typography.H2>
                    <IndexSpeakerCarrousel speakers={speakers} />
                </ContentContainer>
            </div>
        </section>
    )
}

export default IndexSpeakersNew
