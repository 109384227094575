import React from "react"
import { Helmet } from "react-helmet"
import metaImg from "../../../assets/meta/landing.jpeg"

const IndexHelmet = () => {
    return (
        <Helmet>
            <title>ZenCon0 Horizen Ecosystem Summit 2022</title>
            <meta
                property="og:title"
                content="ZenCon0 Horizen Ecosystem Summit 2022"
            />
            <meta
                name="twitter:title"
                content="ZenCon0 Horizen Ecosystem Summit 2022"
            />
            <meta name="theme-color" content="#020E30" />
            <meta
                name="description"
                content="ZenCon0 is the first ever summit to celebrate what we have achieved, share ideas for growth, and envision together the future of the broader Horizen ecosystem."
            />
            <meta
                property="og:description"
                content="ZenCon0 is the first ever summit to celebrate what we have achieved, share ideas for growth, and envision together the future of the broader Horizen ecosystem."
            />
            <meta
                name="twitter:description"
                content="ZenCon0 is the first ever summit to celebrate what we have achieved, share ideas for growth, and envision together the future of the broader Horizen ecosystem."
            />
            <meta property="og:image" content={metaImg} />
            <meta name="twitter:image" content={metaImg} />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="canonical" href="https://zencon.events/" />
            <meta property="og:url" content="https://zencon.events/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ZenCon0" />
        </Helmet>
    )
}

export default IndexHelmet
