import React from "react"

const ZenConLogoMobile: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="109"
            height="21"
            viewBox="0 0 109 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M25.2431 20.5654C23.0716 20.5654 21.3145 19.9109 19.9691 18.5992C18.6264 17.2874 17.9551 15.5813 17.9551 13.4781V13.1061C17.9551 11.6406 18.2231 10.3456 18.759 9.22407C19.295 8.10252 20.0796 7.23549 21.1073 6.62018C22.1378 6.00767 23.3589 5.70001 24.7707 5.70001C26.7598 5.70001 28.3291 6.32371 29.4811 7.57391C30.6332 8.82411 31.2078 10.5666 31.2078 12.7985V14.5325H22.4417C22.5992 15.3352 22.9445 15.9673 23.475 16.426C24.0082 16.8847 24.6961 17.114 25.5415 17.114C26.9367 17.114 28.0279 16.619 28.8125 15.6317L30.8265 18.0426C30.2768 18.8117 29.4977 19.4214 28.4921 19.8773C27.4837 20.3332 26.4007 20.5598 25.2403 20.5598L25.2431 20.5654ZM24.7458 9.14575C23.4556 9.14575 22.6876 10.0128 22.4417 11.7412H26.8897V11.3972C26.9063 10.6812 26.7294 10.1275 26.3537 9.73589C25.978 9.34433 25.442 9.14575 24.7431 9.14575H24.7458Z"
                fill="white"
            />
            <path
                d="M37.0315 5.96573L37.1751 7.64944C38.1531 6.35169 39.4986 5.70282 41.217 5.70282C42.6922 5.70282 43.7918 6.14752 44.5212 7.03972C45.2505 7.93192 45.6262 9.27442 45.6539 11.0672V20.2997H41.2308V11.2518C41.2308 10.5274 41.0871 9.99601 40.7998 9.65479C40.5125 9.31637 39.9876 9.14576 39.2306 9.14576C38.3658 9.14576 37.7249 9.48978 37.3077 10.1778V20.2997H32.8984V5.96573H37.0342H37.0315Z"
                fill="white"
            />
            <path
                d="M63.7113 13.769C63.6506 15.1115 63.2914 16.2973 62.6394 17.3266C61.9846 18.3558 61.0674 19.1529 59.885 19.7179C58.7025 20.2829 57.3543 20.5654 55.8348 20.5654C53.3318 20.5654 51.3593 19.7403 49.9199 18.0873C48.4805 16.4372 47.7622 14.1046 47.7622 11.0924V10.1386C47.7622 8.24796 48.0882 6.59501 48.7374 5.177C49.3867 3.75899 50.3232 2.66541 51.5444 1.89068C52.7655 1.11875 54.18 0.72998 55.7851 0.72998C58.0975 0.72998 59.9541 1.34529 61.3603 2.57871C62.7637 3.80933 63.5622 5.50703 63.7555 7.67181H59.175C59.1391 6.49712 58.849 5.65526 58.2992 5.14064C57.7494 4.62881 56.9123 4.3715 55.7879 4.3715C54.6635 4.3715 53.807 4.80502 53.2766 5.66925C52.7434 6.53628 52.4643 7.91793 52.4395 9.817V11.1819C52.4395 13.2404 52.6936 14.7115 53.2047 15.5925C53.7158 16.4763 54.5944 16.9182 55.8404 16.9182C56.8957 16.9182 57.7024 16.6665 58.2605 16.1631C58.8186 15.6597 59.1142 14.8598 59.1501 13.7662H63.7169L63.7113 13.769Z"
                fill="white"
            />
            <path
                d="M65.1255 12.9998C65.1255 11.5706 65.399 10.2953 65.9488 9.17933C66.4986 8.06338 67.2887 7.20474 68.3164 6.60341C69.3469 6.00208 70.5542 5.70282 71.9411 5.70282C74.0601 5.70282 75.7315 6.36847 76.9527 7.69699C78.1738 9.0255 78.7843 10.8351 78.7843 13.1201V13.2795C78.7843 15.5142 78.171 17.2874 76.9471 18.5992C75.7205 19.9109 74.0629 20.5654 71.9687 20.5654C69.8746 20.5654 68.3358 19.9529 67.1146 18.7306C65.8935 17.5084 65.236 15.8499 65.1393 13.7578L65.1255 13.0026V12.9998ZM69.5348 13.2795C69.5348 14.6052 69.7392 15.5758 70.1509 16.1939C70.5597 16.812 71.1675 17.1224 71.9687 17.1224C73.5379 17.1224 74.3419 15.9002 74.375 13.4529V13.0026C74.375 10.4323 73.5628 9.14856 71.9411 9.14856C70.4658 9.14856 69.6674 10.2561 69.5458 12.474L69.532 13.2823L69.5348 13.2795Z"
                fill="white"
            />
            <path
                d="M84.7795 5.96573L84.9232 7.64944C85.9012 6.35169 87.2466 5.70282 88.965 5.70282C90.4403 5.70282 91.5399 6.14752 92.2692 7.03972C92.9986 7.93192 93.3743 9.27442 93.4019 11.0672V20.2997H88.9788V11.2518C88.9788 10.5274 88.8352 9.99601 88.5478 9.65479C88.2605 9.31637 87.7356 9.14576 86.9786 9.14576C86.1139 9.14576 85.4729 9.48978 85.0558 10.1778V20.2997H80.6465V5.96573H84.7823H84.7795Z"
                fill="white"
            />
            <path
                d="M0 20.2997L13.1947 1.01245H18.4853L5.29059 20.2997H0Z"
                fill="white"
            />
            <path
                d="M9.17196 16.7225L6.87891 20.2997H16.5152V16.7225H9.17196Z"
                fill="white"
            />
            <path
                d="M7.89307 1.01245V4.58965H11.2774L13.5704 1.01245H7.89307Z"
                fill="white"
            />
            <path
                d="M3.79607 5.60492C5.32492 5.60492 6.5643 4.35022 6.5643 2.80246C6.5643 1.2547 5.32492 0 3.79607 0C2.26721 0 1.02783 1.2547 1.02783 2.80246C1.02783 4.35022 2.26721 5.60492 3.79607 5.60492Z"
                fill="#62C5F8"
            />
            <path
                d="M104.574 7.88157L107.638 3.40379C107.638 3.40379 107.633 3.3982 107.63 3.3926C107.514 3.2164 107.39 3.04299 107.257 2.88078C106.995 2.55914 106.704 2.27945 106.395 2.03053C105.331 1.17748 103.986 0.746765 102.35 0.746765C100.24 0.746765 98.6069 1.45437 97.4493 2.86679C96.2945 4.27921 95.7144 6.29016 95.7144 8.89404V12.4181C95.7144 14.0515 95.9464 15.4527 96.4106 16.6218C96.6675 17.2707 96.9962 17.844 97.3941 18.3475C97.3941 18.3502 97.3996 18.353 97.4024 18.3586L100.207 14.2612L104.572 7.87878L104.574 7.88157ZM100.14 8.0326C100.165 6.74325 100.353 5.78113 100.696 5.14624C101.041 4.51135 101.591 4.19251 102.35 4.19251C103.11 4.19251 103.693 4.53372 104.052 5.21896C104.074 5.26371 104.091 5.32244 104.113 5.36719L100.14 11.1735V8.0326V8.0326Z"
                fill="white"
            />
            <path
                d="M108.218 4.50296L104.588 9.80862V13.0921C104.58 14.4794 104.397 15.4974 104.038 16.1463C103.682 16.7952 103.127 17.1196 102.375 17.1196C101.624 17.1196 101.032 16.7868 100.673 16.1184C100.615 16.0093 100.571 15.875 100.521 15.7492L98.1841 19.1669C99.2698 20.0927 100.662 20.5654 102.375 20.5654C104.478 20.5654 106.108 19.8578 107.262 18.4453C108.417 17.0329 108.997 15.022 108.997 12.4181V8.90803C108.997 7.17677 108.732 5.714 108.218 4.50296V4.50296Z"
                fill="white"
            />
        </svg>
    )
}

export default ZenConLogoMobile
