import React, { useCallback, useState } from "react"
import Typography from "../../../Typography"
import ZModal from "../../ZModal"

const IndexSpeakerModal: React.FC<{ name: string; bio: string }> = ({
    name,
    bio,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const onRequestClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    return (
        <>
            <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="transition-colors font-roboto text-horizen-purple-2 text-[16px] leading-[25px] font-bold h-[32px] max-w-[90px] rounded-[5px] w-full border border-horizen-purple-2 flex justify-center items-center hover:text-white focus:text-white md:mr-auto"
            >
                Bio
            </button>
            <ZModal isOpen={isOpen} onRequestClose={() => onRequestClose()}>
                <div className="flex p-[55px] flex-col h-[80vh] md:h-[450px] w-[500px] max-w-full overflow-y-auto">
                    <Typography.H3>
                        {name}{" "}
                        <span className="text-horizen-purple-2">Bio</span>
                    </Typography.H3>
                    <p
                        className="mt-8 font-roboto font-normal text-[18px] leading-[30px] text-horizen-white-2"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: bio }}
                    />
                </div>
            </ZModal>
        </>
    )
}

export default IndexSpeakerModal
