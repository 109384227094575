/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import IndexSpeaker, { IndexSpeakerProps } from "./indexSpeaker"
import {
    CarrouselArrowLeft,
    CarrouselArrowRight,
} from "../../../../assets/ZenCon/index/CarrouselArrows"

interface ArrowBtnProps {
    direction: "left" | "right"
    onClick: () => void
}

const ArrowBtn: React.FC<ArrowBtnProps> = ({ direction, onClick }) => {
    return (
        <button
            type="button"
            aria-label={`Scroll ${direction} carousel`}
            onClick={onClick}
            className="flex shrink-0 items-center justify-center w-[46px] h-[46px] rounded-[10px] border border-horizen-purple-2 bg-horizen-bg group hover:bg-horizen-purple transition-colors"
        >
            {direction === "left" ? (
                <CarrouselArrowLeft className="fill-horizen-purple-2 group-hover:fill-white" />
            ) : (
                <CarrouselArrowRight className="fill-horizen-purple-2 group-hover:fill-white" />
            )}
        </button>
    )
}

interface DotBtnProps {
    selected: boolean
    slide: number
    onClick: () => void
}

const DotBtn: React.FC<DotBtnProps> = ({ selected, slide, onClick }) => {
    return (
        <button
            type="button"
            aria-label={`Go to slide ${slide}`}
            onClick={onClick}
            className={`w-[13px] h-[13px] rounded-full bg-horizen-purple-2 hover:bg-horizen-purple transition-colors ${
                selected ? "!bg-white" : ""
            }`}
        />
    )
}

interface IndexSpeakerCarrouselProps {
    speakers: IndexSpeakerProps[]
}

class SLIDES_PER_VIEW {
    static readonly MOBILE = 1
    static readonly TABLET = 3
    static readonly DESKTOP = 4
}

const IndexSpeakerCarrousel: React.FC<IndexSpeakerCarrouselProps> = ({
    speakers,
}) => {
    const [scrollSnaps, setScrollSnaps] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [slidesInView, setSlidesInView] = useState([])
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        dragFree: true,
        skipSnaps: true,
        align: "start",
        slidesToScroll: SLIDES_PER_VIEW.MOBILE,
        breakpoints: {
            "(min-width: 768px)": {
                slidesToScroll: SLIDES_PER_VIEW.TABLET,
            },
            "(min-width: 1024px)": {
                slidesToScroll: SLIDES_PER_VIEW.DESKTOP,
            },
        },
    })

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [emblaApi, setSelectedIndex])

    const scrollTo = useCallback(
        (index) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    )

    const findSlidesInView = useCallback(() => {
        if (!emblaApi) return

        setSlidesInView((prevSlidesInView) => {
            if (prevSlidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off("select", findSlidesInView)
            }
            const inView = emblaApi
                .slidesInView(true)
                .filter((index) => prevSlidesInView.indexOf(index) === -1)
            return prevSlidesInView.concat(inView)
        })
    }, [emblaApi, setSlidesInView])

    useEffect(() => {
        if (!emblaApi) return
        setScrollSnaps(emblaApi.scrollSnapList())

        findSlidesInView()
        emblaApi.on("select", findSlidesInView)

        onSelect()
        emblaApi.on("select", onSelect)
    }, [emblaApi, setScrollSnaps, findSlidesInView, onSelect])

    return (
        <div>
            <div className="overflow-hidden" ref={emblaRef}>
                <div className="flex">
                    {speakers.map((speaker, index) => (
                        <div
                            key={speaker.name}
                            className="grow-0 shrink-0 flex justify-center basis-[100%] md:basis-[33%] lg:basis-[25%]"
                        >
                            <IndexSpeaker
                                key={speaker.name}
                                {...speaker}
                                inView={slidesInView.indexOf(index) > -1}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`mt-0 -top-[138px] md:top-0 gap-[220px] relative z-10 h-0 md:h-auto flex justify-center items-center md:gap-12 md:mt-5  `}
            >
                <ArrowBtn
                    direction="left"
                    onClick={() => scrollTo(selectedIndex - 1)}
                />
                <div className="hidden md:flex gap-3">
                    {scrollSnaps.map((snap, index) => (
                        <DotBtn
                            key={snap}
                            slide={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </div>
                <ArrowBtn
                    direction="right"
                    onClick={() => scrollTo(selectedIndex + 1)}
                />
            </div>
        </div>
    )
}

export default IndexSpeakerCarrousel
