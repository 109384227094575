import React, { useCallback, useState } from "react"

import TwitterLogo from "../../SocialButtons/TwitterLogo"
import LinkedInLogo from "../../SocialButtons/LinkedInLogo"
import Typography from "../../../Typography"
import IndexSpeakerModal from "./indexSpeakerModal"

const SocialBtns: React.FC<{
    logo: React.ReactNode
    href: string
    alt: string
}> = ({ logo, href, alt }) => {
    return (
        <a
            href={href}
            target="_blank"
            className="transition-colors flex justify-center items-center h-[31px] w-[31px] rounded-full bg-horizen-purple-2 hover:bg-horizen-purple"
            rel="noreferrer"
            aria-label={alt}
        >
            {logo}
        </a>
    )
}

export interface IndexSpeakerProps {
    imgUrl: string
    name: string
    companyPosition: string
    bio: string
    twitter: string
    linkedin: string
    inView?: boolean
}

const IndexSpeaker: React.FC<IndexSpeakerProps> = ({
    bio,
    companyPosition,
    imgUrl,
    linkedin,
    name,
    twitter,
    inView = false,
}) => {
    const [hasLoaded, setHasLoaded] = useState(false)

    const setLoaded = useCallback(() => {
        if (inView) setHasLoaded(true)
    }, [inView, setHasLoaded])

    return (
        <div className="embla__slide">
            <div className="embla__slide__inner">
                <div className="max-w-[200px] text-center flex flex-col md:text-left md:h-[390px]">
                    <div className="relative h-[200px] w-[200px] bg-horizen-purple-2 border-horizen-purple-2 border-[3px] rounded-full rounded-bl-none ">
                        <img
                            alt={name}
                            style={{
                                opacity: hasLoaded ? "1" : "0",
                            }}
                            src={inView ? imgUrl : ""}
                            onLoad={setLoaded}
                            className="h-full w-full bg-horizen-bg rounded-full object-cover opacity-0 transition-opacity"
                        />
                    </div>
                    <div className="flex gap-3 mt-7 justify-center md:justify-start">
                        <IndexSpeakerModal name={name} bio={bio} />

                        {twitter && (
                            <SocialBtns
                                href={twitter}
                                alt="twitter logo"
                                logo={<TwitterLogo height={18} width={18} />}
                            />
                        )}
                        {linkedin && (
                            <SocialBtns
                                href={linkedin}
                                alt="linkedin logo"
                                logo={<LinkedInLogo height={16} width={16} />}
                            />
                        )}
                    </div>
                    <Typography.Body1 className="mt-4">{name}</Typography.Body1>
                    <Typography.Body2 className="font-normal !text-[16px] !leading-[25px] text-horizen-white-2">
                        {companyPosition}
                    </Typography.Body2>
                </div>
            </div>
        </div>
    )
}

export default IndexSpeaker
