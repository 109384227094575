import React from "react"
import Layout from "../templates/Layout"
import IndexHero from "../components/ZenCon/index/indexHero"
import IndexAbout from "../components/ZenCon/index/IndexAbout"
import NewsletterForm from "../components/ZenCon/NewsletterForm"
import IndexHelmet from "../components/ZenCon/index/IndexHelmet"
import IndexNFT from "../components/ZenCon/index/IndexNFT"
import InedexMainVideo from "../components/ZenCon/index/InedexMainVideo"
import IndexNumbers from "../components/ZenCon/index/IndexNumbers"
import IndexReplays from "../components/ZenCon/index/IndexReplays"
import IndexSpeakersNew from "../components/ZenCon/index/IndexSpeakersNew"
import IndexGallery from "../components/ZenCon/index/IndexGallery"

const index = () => {
    return (
        <Layout>
            <IndexHelmet />
            <IndexHero />
            {/* <IndexMural /> */}
            <IndexAbout />
            <InedexMainVideo />
            <IndexNumbers />
            <IndexGallery />
            <IndexReplays />
            {/* <IndexTwitter /> */}
            <IndexSpeakersNew />
            <IndexNFT />
            <NewsletterForm />
        </Layout>
    )
}

export default index
