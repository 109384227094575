import React, { useCallback, useEffect, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import Typography from "../../Typography"
import ContentContainer from "../../../templates/ContentContainer"

import img1 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-1.jpg"
import img4 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-4.jpg"
import img5 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-5.jpg"
import img6 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-6.jpg"
import img7 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-7.jpg"
import img8 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-8.jpg"
import img9 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-9.jpg"
import img10 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-10.jpg"
import img11 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-11.jpg"
import img12 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-12.jpg"
import img13 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-13.jpg"
import img14 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-14.jpg"
import img15 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-15.jpg"
import img16 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-16.jpg"
import img17 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-17.jpg"
import img18 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-18.jpg"
import img19 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-19.jpg"
import img20 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-20.jpg"
import img21 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-21.jpg"
import img22 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-22.jpg"
import img23 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-23.jpg"
import img24 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-24.jpg"
import img25 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-25.jpg"
import img26 from "../../../assets/BackgroundImages/ZenCon/index/gallery/gallery-26.jpg"

const autoplay = Autoplay({ delay: 5000 })

const gallery = [
    img1,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
]

interface SlideProps {
    imgUrl: string
    inView: boolean
}

const Slide: React.FC<SlideProps> = ({ imgUrl, inView }) => {
    const [hasLoaded, setHasLoaded] = useState(false)

    const setLoaded = useCallback(() => {
        if (inView) setHasLoaded(true)
    }, [inView, setHasLoaded])

    return (
        <div
            key={imgUrl}
            className="grow-0 shrink-0 flex justify-center bg-horizen-purple"
        >
            <img
                alt="ZenCon"
                style={{
                    opacity: hasLoaded ? "1" : "0",
                }}
                src={inView ? imgUrl : ""}
                onLoad={setLoaded}
                className="w-[100vw] md:w-[400px] md:h-[266px] lg:w-[618px] lg:h-[413px] transition-opacity"
            />
        </div>
    )
}

const IndexGallery = () => {
    const [slidesInView, setSlidesInView] = useState([])

    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true,
            inViewThreshold: 0,
            dragFree: true,
            skipSnaps: true,
            slidesToScroll: 1,
            speed: 5,
            draggable: false,

            align: "start",
        },
        [autoplay]
    )

    const findSlidesInView = useCallback(() => {
        if (!emblaApi) return

        setSlidesInView((prevSlidesInView) => {
            if (prevSlidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off("select", findSlidesInView)
            }
            const inView = emblaApi
                .slidesInView(true)
                .filter((index) => prevSlidesInView.indexOf(index) === -1)
            return prevSlidesInView.concat(inView)
        })
    }, [emblaApi, setSlidesInView])

    useEffect(() => {
        if (!emblaApi) return

        findSlidesInView()
        emblaApi.on("select", findSlidesInView)
    }, [emblaApi, findSlidesInView])

    return (
        <section className="pt-32 lg:pt-0">
            <ContentContainer>
                <Typography.H2>Gallery</Typography.H2>
            </ContentContainer>
            <div className="mx-auto max-w-screen-[100vw] 2xl:max-w-screen-xl 2xl:px-12 mt-12">
                <div className="overflow-hidden" ref={emblaRef}>
                    <div className="flex">
                        {gallery.map((img) => (
                            <Slide
                                imgUrl={img}
                                inView={
                                    slidesInView.indexOf(
                                        gallery.indexOf(img)
                                    ) !== -1
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IndexGallery
