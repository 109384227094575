import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Typography from "../../Typography"
import ButtonSolid from "../ButtonSolid"
import YoutubeVideo from "../YoutubeVideo"

const IndexReplays = () => {
    return (
        <section className="pt-32 relative z-10" id="replay-section">
            <ContentContainer>
                <Typography.H2>Watch Replays</Typography.H2>
                <div className="grid sm:grid-cols-2 mt-12 gap-y-10 gap-x-9">
                    <YoutubeVideo videoId="fmXxLQ9n6J8" />
                    <YoutubeVideo videoId="LrzzAbcroPw" />
                    <YoutubeVideo videoId="3pRvx4efAEw" />
                    <YoutubeVideo videoId="2OxheNnxy0E" />
                </div>
                <ButtonSolid
                    className="mt-14 max-w-[282px] w-full mx-auto"
                    href="https://www.youtube.com/playlist?list=PLhZisbhyYIXJGu59-RosTGiHvs7qJg7l_"
                >
                    Watch More Replays
                </ButtonSolid>
            </ContentContainer>
        </section>
    )
}

export default IndexReplays
