import React from "react"

const XSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.39711e-05 33L33.9999 0L35.3929 1.43516L1.393 34.4352L4.39711e-05 33Z"
                fill="#8A3CE9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.3928 33L1.39295 1.14441e-05L0 1.43517L33.9999 34.4352L35.3928 33Z"
                fill="#8A3CE9"
            />
        </svg>
    )
}

export default XSVG
