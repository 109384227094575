import React from "react"
import Typography from "../../Typography"
import Logo from "../../../assets/ZenCon/index/logo"
import HeroImg1 from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-hero-1.jpg"
import HeroImg4K from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-hero-4k.jpg"
import ContentContainer from "../../../templates/ContentContainer"

const indexHero = () => {
    return (
        <section className="relative h-[470px] flex justify-center items-center">
            <picture className="absolute inset-0 z-0 bg-horizen-purple">
                <source srcSet={HeroImg1} media="(max-width: 1438px)" />
                <source srcSet={HeroImg4K} media="(min-width: 1439px)" />
                <img
                    src={HeroImg4K}
                    alt="Zencon0"
                    className="absolute inset-0 w-full h-full object-cover"
                />
            </picture>

            <ContentContainer>
                <div className="px-6 mx-auto sm:px-0 text-center relative">
                    <div className="flex max-w-[250px] lg:max-w-[350px] m-auto">
                        <Logo />
                    </div>
                    <Typography.Body1 className="!text-horizen-blue-1 mt-[20px]">
                        September 11 - 13, 2022
                    </Typography.Body1>
                    <Typography.H3 className="mt-[6px]">
                        Milan, Italy
                    </Typography.H3>
                </div>

                <div className="absolute left-0 w-full bottom-10 flex sm:hidden justify-center items-center gap-3">
                    <Typography.Link1
                        href="#replay-section"
                        className="text-white font-bold text-base"
                    >
                        Replays
                    </Typography.Link1>
                    <div className="text-white -mt-1">|</div>
                    <Typography.Link1
                        href="#speakers-section"
                        className="text-white font-bold text-base"
                    >
                        Speakers
                    </Typography.Link1>
                    <div className="text-white -mt-1">|</div>
                    <Typography.Link1
                        href="#zencon-nft-section"
                        className="text-white font-bold text-base"
                    >
                        ZenCon NFT
                    </Typography.Link1>
                </div>
            </ContentContainer>
        </section>
    )
}

export default indexHero
