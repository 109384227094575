import React, { useState } from "react"
import axios from "axios"
import ContentContainer from "../../templates/ContentContainer"
import Typography from "../Typography"
import ButtonSolid from "./ButtonSolid"

const NewsletterForm = () => {
    const [email, setEmail] = useState("")
    const [subscribed, setSubscribed] = useState(false)
    const [waitingForResponse, setWaitingForResponse] = useState(false)

    const handleNewsletterSubscribe = () => {
        setWaitingForResponse(true)
        axios
            .post(
                "https://hook.integromat.com/mwbuoaifmld4xb8baharecxmwv7i5xer",
                {
                    email,
                }
            )
            .then(() => {
                setSubscribed(true)
            })
            .catch(() => {
                setEmail("You have to provide a valid email!")
            })
            .finally(() => setWaitingForResponse(false))
    }

    return (
        <section className="translate-y-1/2">
            <ContentContainer>
                <div className="backdrop-blur-[37px] rounded-[10px] overflow-hidden border-2 border-horizen-purple bg-[#020E30] px-7 py-8 md:py-[38px] md:px-[50px]">
                    <div
                        className={`flex flex-col lg:flex-row lg:items-center gap-y-6 gap-x-8 lg:gap-x-[100px] ${
                            subscribed ? "md:flex-row" : ""
                        }`}
                    >
                        <Typography.H3 className="text-left max-w-[290px]">
                            Subscribe for the latest event updates
                        </Typography.H3>
                        <div className="grow flex flex-col gap-y-8 items-center md:flex-row md:space-x-5 ">
                            {!subscribed ? (
                                <>
                                    <input
                                        placeholder="Your email"
                                        className="w-full bg-transparent text-horizen-white-2 text-sm h-[46px] border-b border-horizen-white-2 grow outline-none"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <ButtonSolid
                                        onClick={handleNewsletterSubscribe}
                                        loading={waitingForResponse}
                                        className="max-w-[350px] w-full md:max-w-[180px]"
                                    >
                                        Subscribe
                                    </ButtonSolid>
                                </>
                            ) : (
                                <span className="text-[24px] text-horizen-blue-2 block w-full text-left md:text-center">
                                    Thank you for subscribing!
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default NewsletterForm
