import gsap, { Linear } from "gsap"
import React, { useLayoutEffect, useRef } from "react"

const LoadingBullets = () => {
    const bullet1 = useRef(null)
    const bullet2 = useRef(null)
    const bullet3 = useRef(null)

    const className =
        "h-[14px] w-[14px] bg-horizen-white-1 rounded-full opacity-[0.3]"

    useLayoutEffect(() => {
        const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.3 })

        const OPACITY_AMOUNT = {
            LOW: 0.3,
            MID: 0.6,
            HIGH: 1,
        }

        const bullets = [bullet1.current, bullet2.current, bullet3.current]

        const timestamps = [
            [OPACITY_AMOUNT.HIGH, OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.LOW],
            [OPACITY_AMOUNT.MID, OPACITY_AMOUNT.HIGH, OPACITY_AMOUNT.LOW],
            [OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.MID, OPACITY_AMOUNT.HIGH],
            [OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.MID],
            [OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.LOW, OPACITY_AMOUNT.LOW],
        ]

        timestamps.forEach((timestamp, tIndex) => {
            bullets.forEach((bullet, bIndex) =>
                tl.to(
                    bullet,
                    {
                        opacity: timestamp[bIndex],
                        duration: 0.1,
                        ease: Linear.easeOut,
                    },
                    `step${tIndex}`
                )
            )
        })
    }, [bullet1, bullet2, bullet3])

    return (
        <div className="flex gap-2 justify-center">
            <div ref={bullet1} className={className} />
            <div ref={bullet2} className={className} />
            <div ref={bullet3} className={className} />
        </div>
    )
}

export default LoadingBullets
