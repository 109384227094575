import React from "react"
import Typography from "../../Typography"
import bgImg from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-about-bg.png"
import HorizenLabsLogo from "../HorizenLabsLogo"
import ContentContainer from "../../../templates/ContentContainer"
import HorizenLogo from "../SocialButtons/HorizenLogo"
import XSVG from "./XSVG"

const IndexAbout = () => {
    return (
        <section className="bg-gradient-radial-to-bl from-[#4B14A3] 2xl:max-w-screen-xl mx-auto lg:h-[740px] relative">
            <img
                src={bgImg}
                alt="cathedral"
                className="hidden lg:block absolute object-contain h-[740px]"
            />
            <div className="relative z-10">
                <ContentContainer>
                    <div className="grid grid-cols-1 lg:grid-cols-[2fr,8fr] gap-[50px] lg:gap-[108px] pt-32 lg:pt-48">
                        <div>
                            <Typography.H2>About ZenCon0</Typography.H2>
                        </div>

                        <div>
                            <Typography.Body1 className="mb-7">
                                ZenCon0 is the first ever summit to celebrate
                                what we have achieved, share ideas for growth,
                                and envision together the future of the broader
                                Horizen ecosystem.
                            </Typography.Body1>
                            <Typography.Body2 className="mt-8">
                                ZenCon0 is hosted and organized by Horizen Labs,
                                and we are joined by our investors, partners,
                                advisors, and community members who are integral
                                to the growth and success of the Horizen
                                ecosystem.
                            </Typography.Body2>
                            <div className="flex items-center mt-[30px]">
                                <Typography.Link1
                                    href="https://horizenlabs.io/"
                                    target="_blank"
                                    className="mt-[14px] mr-[18px]"
                                    aria-label="Horizen Labs logo"
                                >
                                    <HorizenLabsLogo className="max-w-full" />
                                </Typography.Link1>
                                <XSVG className="min-w-[20px] max-w-full" />
                                <Typography.Link1
                                    href="https://www.horizen.io/"
                                    target="_blank"
                                    className="ml-[4px]"
                                    aria-label="Horizen logo"
                                >
                                    <HorizenLogo className="max-w-full" />
                                </Typography.Link1>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </div>
        </section>
    )
}

export default IndexAbout
