import React from "react"

const TwitterLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M20.7275 1.98952C19.9515 2.33319 19.1283 2.55874 18.2854 2.65867C19.1737 2.12797 19.8383 1.29191 20.1549 0.306805C19.3201 0.80217 18.4065 1.15079 17.454 1.33753C16.8126 0.656842 15.9648 0.206335 15.0418 0.0556308C14.1187 -0.0950733 13.1717 0.0624162 12.3471 0.503758C11.5225 0.945099 10.8662 1.64572 10.4796 2.49737C10.0931 3.34902 9.99771 4.30428 10.2083 5.21553C8.5174 5.13099 6.86316 4.69168 5.35305 3.92615C3.84295 3.16062 2.51077 2.08598 1.44305 0.772043C0.89934 1.70861 0.732812 2.81713 0.977351 3.8721C1.22189 4.92708 1.85913 5.84929 2.75944 6.45113C2.084 6.42968 1.42339 6.24764 0.832309 5.92007V5.97239C0.832175 6.95451 1.17196 7.90641 1.79396 8.66646C2.41596 9.42652 3.28182 9.94787 4.24456 10.142C3.87948 10.2428 3.50233 10.2932 3.12358 10.2918C2.85527 10.2921 2.58752 10.2669 2.324 10.2163C2.59526 11.0613 3.12408 11.8002 3.83632 12.3295C4.54856 12.8588 5.40855 13.1521 6.2958 13.1681C4.51094 14.5613 2.24872 15.1944 0 14.9302C1.8279 16.1009 3.9372 16.7581 6.10658 16.8328C8.27596 16.9076 10.4255 16.3971 12.3296 15.355C14.2338 14.3129 15.8224 12.7775 16.9288 10.91C18.0352 9.04246 18.6186 6.91159 18.6178 4.74092C18.6178 4.55611 18.6141 4.37204 18.6065 4.19076C19.4391 3.58919 20.1575 2.84364 20.7278 1.98928"
                fill="white"
            />
        </svg>
    )
}

export default TwitterLogo
