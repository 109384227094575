import React from "react"
import { Helmet } from "react-helmet"

import Footer from "../components/ZenCon/Footer"
import Header from "../components/ZenCon/Header"

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div
            className="overflow-x-hidden bg-horizen-bg"
            style={{ overflowAnchor: "none" }}
        >
            <Helmet>
                <link
                    rel="preload"
                    as="style"
                    href="https://use.typekit.net/elr1akh.css"
                />
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/elr1akh.css"
                />
            </Helmet>
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout
