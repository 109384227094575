import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Typography from "../../Typography"
import NFTImg from "../../../assets/BackgroundImages/ZenCon/index/Zencon0-nft-zero-gravity.png"

const IndexNFT = () => {
    return (
        <section className="pt-24" id="zencon-nft-section">
            <ContentContainer>
                <div className="grid grid-cols-1 gap-20 lg:grid-cols-2">
                    <div className="relative order-2 h-[250px] lg:order-1 sm:h-[400px] lg:h-[534px]">
                        <div
                            className="bg-no-repeat bg-center bg-contain relative h-[668px] w-[907px] max-w-[100vw] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 md:scale-[0.8] lg:absolute"
                            style={{ backgroundImage: `url(${NFTImg})` }}
                        />
                    </div>
                    <div className="order-1 flex flex-col justify-center relative z-10 lg:order-2">
                        <Typography.H2>Zero Gravity</Typography.H2>
                        <Typography.Body1 className="mt-12">
                            Zero Gravity is an exclusive NFT line created for
                            ZenCon0. It is a series of 250 copies of a single
                            artwork that will be dropped to ZenCon0
                            participants.
                        </Typography.Body1>
                        <Typography.Body2 className="mt-8">
                            Zero Gravity will be the first Horizen NFT created
                            on{" "}
                            <a
                                href="https://tokenmint.global/"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                            >
                                TokenMint
                            </a>
                            .
                        </Typography.Body2>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default IndexNFT
