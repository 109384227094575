import React from "react"

const LinkedInLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M4.22284 17.9795H0.491666V6.04009H4.22284V17.9795ZM2.26164 4.54649H2.23475C1.95024 4.56783 1.66437 4.53014 1.39511 4.43579C1.12585 4.34144 0.879014 4.19246 0.670047 3.9982C0.461081 3.80394 0.294505 3.5686 0.180786 3.30693C0.0670674 3.04526 0.00865387 2.76291 0.00920472 2.4776C-0.0320377 2.02064 0.0659508 1.56189 0.290308 1.16166C0.514666 0.76144 0.854876 0.438472 1.26622 0.23522C1.67757 0.0319686 2.14081 -0.0420525 2.59502 0.0228858C3.04922 0.0878242 3.47311 0.288683 3.81102 0.599058C4.14894 0.909433 4.38503 1.31479 4.48825 1.76185C4.59147 2.20891 4.55698 2.67674 4.38934 3.10384C4.2217 3.53094 3.92877 3.89731 3.54902 4.1548C3.16926 4.41229 2.72045 4.54884 2.26164 4.54649ZM19.1494 17.9795H14.9189V11.8012C14.9189 10.1841 14.2578 9.08063 12.8022 9.08063C12.3547 9.0823 11.919 9.2244 11.5566 9.48688C11.1942 9.74936 10.9233 10.119 10.7822 10.5436C10.6936 10.8608 10.6627 11.1914 10.6909 11.5196V17.98H6.49994C6.49994 17.98 6.5544 7.0356 6.49994 6.04054H10.6909V7.91429C11.0482 7.2539 11.5919 6.71321 12.2543 6.3596C12.9166 6.00598 13.6684 5.85507 14.4159 5.92564C17.0668 5.92564 19.1498 7.64342 19.1498 11.341L19.1494 17.9795Z"
                fill="white"
            />
        </svg>
    )
}

export default LinkedInLogo
