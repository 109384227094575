import React from "react"

type SocialButtonProps = {
    href: string
    alt?: string
    icon: React.ReactNode
}

const SocialButton = ({ icon, href, alt }: SocialButtonProps) => {
    return (
        <a
            href={href}
            className="bg-horizen-purple hover:bg-[#5600BF] h-[55px] w-[55px] flex justify-center items-center rounded-full"
            aria-label={alt}
        >
            {icon}
        </a>
    )
}

export default SocialButton
