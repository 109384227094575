import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import Typography from "../Typography"
import ZenConLogo from "./ZenConLogo"
import ZenConLogoMobile from "./ZenConLogoMobile"

const Header = () => {
    return (
        <header className="bg-transparent z-[1] py-8 absolute w-full">
            <ContentContainer>
                <div className="flex justify-between items-center">
                    <a
                        href="/"
                        aria-label="page logo"
                        className="hidden sm:block"
                    >
                        <ZenConLogo className="w-full h-full max-w-[100px] sm:max-w-[150px]" />
                    </a>
                    <a
                        href="/"
                        aria-label="page logo"
                        className="block sm:hidden"
                    >
                        <ZenConLogoMobile className="max-w-[109px]" />
                    </a>
                    <div className="hidden sm:flex justify-center items-center gap-3">
                        <Typography.Link1
                            href="#replay-section"
                            className="text-white font-bold text-base"
                        >
                            Replays
                        </Typography.Link1>
                        <div className="text-white -mt-1">|</div>
                        <Typography.Link1
                            href="#speakers-section"
                            className="text-white font-bold text-base"
                        >
                            Speakers
                        </Typography.Link1>
                        <div className="text-white -mt-1">|</div>
                        <Typography.Link1
                            href="#zencon-nft-section"
                            className="text-white font-bold text-base"
                        >
                            ZenCon NFT
                        </Typography.Link1>
                    </div>
                </div>
            </ContentContainer>
        </header>
    )
}

export default Header
